import React from 'react';
import Radio from './Radio';
import './App.css';
import pyramide from './assets/pyramide.jpg';
import { FaPlayCircle } from 'react-icons/fa';

const App = () => {
  return (
    <div className='app'>
        <Radio />
    </div>
  )
}

export default App