import React, { useState, useEffect } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import image from './assets/pyramide.jpg';

const Radio = () => {
  return (
    <div className='radio'>
        <div className='stations'>
            <img 
                className='image'
                src={image}
                alt='Pyramide fm'
            />
        </div>

        <AudioPlayer 
            className='player'
            autoPlay={true}
            src='http://stream.zeno.fm/ff285rpef0hvv'
            showJumpControls={false}
            layout='stacked'
            customProgressBarSection={["CURRENT_TIME", "PROGRESS_BAR", "DURATION"]}
            customControlsSection={[ "MAIN_CONTROLS", "VOLUME_CONTROLS"]}
        />
        {/* <AudioPlayer 
            className='player'
            autoPlay={true}
            src='http://stream.zeno.fm/ff285rpef0hvv'
            showJumpControls={true}
            layout='stacked'
            customProgressBarSection={["CURRENT_TIME", "PROGRESS_BAR", "DURATION"]}
            customControlsSection={["ADDITIONAL_CONTROLS", "MAIN_CONTROLS", "VOLUME_CONTROLS"]}
        /> */}
    </div>
  )
}

export default Radio;